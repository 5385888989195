import store from '@/store/index.js'
let dic = store.state.dic;
export default function Configs(that) {
  const searchItems = [
    {
      label: "",
      span:24,
      type: "date",
      placeholder:'选择日期',
      model: "createTime",
      clearable: true,
    },
  ];
  const tableColumns = [
    {
      label: "文件名称",
      prop: "name",
      align: "center",
      show: true
    },
    {
      label: "文件大小",
      prop: "size",
      align: "center",
      show: true
    },
    {
      label: "创建时间",
      prop: "createTime",
      align: "center",
      show: true
    },
    {
      label: "操作",
      name: 'custom',
      align: "center",
      show: true
    },
  ];
  return {
    searchItems,
    tableColumns
  };
}
