<template>
  <div class="single-bridge-common">
    <div class="tit-search flex space-between">
      <div class="mb-2">
        <el-button class="btn-primary" @click="exportData()">监测数据导出</el-button>
        <el-button class="btn-primary" @click="exportVibData()">索振动数据导出</el-button>
       <el-button class="btn-primary" @click="exportFeatureData()">特征数据导出</el-button>
      </div>
      <c-search
          v-model="pageParams"
          :searchItems="searchItems"
          @search="pageGet(true)"
      ></c-search>
    </div>
    <div class="table-wrapper" >
      <c-table
          :index="false"
          :data="pageData"
          :tableColumns="tableColumns"
          :stripe="true"
          :pagination="pageParams"
          @change="pageGet"
      >
        <template v-slot:custom="{ row }">
          <span class="opt-detail" style="margin-right: 10px" @click="handleDownload(row)">下载</span>
          <span class="opt-delete" @click="pageDel(row.id)">删除</span>
        </template>
      </c-table>
    </div>
    <el-dialog
        :close-on-click-modal="false"
        title="导出"
        :visible.sync="dialogVisible"
        :before-close="beforeDialogClose"
        :destroy-on-close="true"
        width="900px"
    >
      <div class="form-wrapper manage-scroll">
        <el-form ref="form" :model="form.model" :rules="form.rules" label-width="120px">
          <el-row :gutter="20" class="w-full">
            <el-col :span="12">
              <el-form-item label="导出文件名称" prop="name">
                <el-input v-model="form.model.name" placeholder="请填写导出文件名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="导出类型" prop="sign">
                <el-radio-group v-model="form.model.sign">
                  <el-radio v-for="(item,index) in typeList" :key="index" :label="item.value" :disabled="item.value == 0">{{item.label}}</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="时间范围" prop="date">
                <el-date-picker
                    v-model="form.model.date"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-for="(sensor,s) in sensorList" :key="s">
              <el-form-item :label="sensor.name">
                <el-checkbox :indeterminate="sensor.isIndeterminate" v-model="sensor.checkAll" @change="val => handleCheckAll(val,sensor)">全选</el-checkbox>
                <el-checkbox-group v-model="form.model.exportMap[sensor.id]" @change="val => handleCheck(val,sensor)">
                  <el-checkbox v-for="(point,p) in sensor.children" :label="point.id" :key="p">{{point.name}}</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="resetFields">取 消</el-button>
        <el-button class="btn-primary" @click="submit('form')">导 出</el-button>
      </span>
    </el-dialog>

    <el-dialog
        :close-on-click-modal="false"
        title="导出"
        :visible.sync="dialogVibVisible"
        :before-close="beforeDialogClose"
        :destroy-on-close="true"
        width="900px"
    >
      <div class="form-wrapper manage-scroll">
        <el-form ref="form" :model="vibForm.model" :rules="vibForm.rules" label-width="120px">
          <el-row :gutter="20" class="w-full">
            <el-col :span="12">
              <el-form-item label="导出文件名称" prop="name">
                <el-input v-model="vibForm.model.name" placeholder="请填写导出文件名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="时间范围" prop="date">
                <el-date-picker
                    v-model="vibForm.model.date"
                    @change="judgeVibTime"
                    type="datetimerange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-for="(sensor,s) in sensorVibList" :key="s">
              <el-form-item :label="sensor.name">
                <el-checkbox :indeterminate="sensor.isIndeterminate" v-model="sensor.checkAll" @change="val => handleVibCheckAll(val,sensor)">全选</el-checkbox>
                <el-checkbox-group v-model="vibForm.model.exportMap[sensor.id]" @change="val => handleVibCheck(val,sensor)">
                  <el-checkbox v-for="(point,p) in sensor.children" :label="point.id" :key="p">{{point.name}}</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="resetVibFields">取 消</el-button>
        <el-button class="btn-primary" @click="submitVib('form')">导 出</el-button>
      </span>
    </el-dialog>

    <!--  特征值导出  -->
    <el-dialog
        :close-on-click-modal="false"
        title="导出"
        :visible.sync="dialogFeatureVisible"
        :before-close="beforeDialogClose"
        :destroy-on-close="true"
        width="900px"
    >
      <div class="form-wrapper manage-scroll">
        <el-form ref="form" :model="form.model" :rules="form.rules" label-width="120px">
          <el-row :gutter="20" class="w-full">
            <el-col :span="12">
              <el-form-item label="导出文件名称" prop="name">
                <el-input v-model="form.model.name" placeholder="请填写导出文件名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="时间范围" prop="date">
                <el-date-picker
                    v-model="form.model.date"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-for="(sensor,s) in sensorList" :key="s">
              <el-form-item :label="sensor.name">
                <el-checkbox :indeterminate="sensor.isIndeterminate" v-model="sensor.checkAll" @change="val => handleCheckAll(val,sensor)">全选</el-checkbox>
                <el-checkbox-group v-model="form.model.exportMap[sensor.id]" @change="val => handleCheck(val,sensor)">
                  <el-checkbox v-for="(point,p) in sensor.children" :label="point.id" :key="p">{{point.name}}</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="resetFeatureFields">取 消</el-button>
        <el-button class="btn-primary" @click="submitFeature('form')">导 出</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import MPage from "@/mixins/MPage.js";
  import Configs from "./config/index";
  // import common from "../../../../../assets/js/common";
  let mPage = MPage({
    pagePath: "/sensor/export/listPage",
    pageDelPath: "/sensor/export/remove",
    pageDelsPath: "",
    pageParams: {},
  });
  export default {
    mixins: [mPage],
    data(){
      let { searchItems,tableColumns } = Configs(this);
      let validateName = (rule, value, callback) => {
        if (!value) {
          callback(new Error('导出文件名称不能为空'))
        } else {
          this.$http.get('/sensor/export/nameRepeat',{params:{name:value}}).then((result) => {
            if (result.success) {
              if (result.data == 0){
                callback()
              } else {
                callback(new Error('导出文件名重复'))
              }
            } else {
              this.$message.error(result.msg)
            }
          })
        }
      };
      let minVibTime = null;
      let maxVibTime = null;
      return{
        searchItems,
        tableColumns,
        dialogVisible: false,
        dialogVibVisible: false,
        dialogFeatureVisible: false,
        pageData:[],
        isIndeterminate: true,
        typeList:[{value:0,label:'正常'},{value:1,label: '按天'},{value: 2,label: '按月'}],
        checkAll:false,
        checkDetailAll:false,
        form:{
          model:{
            name:'',
            exportMap:{},
            sign:''
          },
          rules:{
            name:[
              { required: true, validator: validateName, trigger: 'blur' }
            ],
            sign:[
              { required: true, message: '请选择导出类型', trigger: 'blur' }
            ],
            date:[
              { required: true, message: '请选择日期', trigger: 'blur' }
            ]
          }
        },
        vibForm:{
          model:{
            name:'',
            exportMap:{},
            sign:'',
            shorthand: ''
          },
          rules:{
            name:[
              { required: true, validator: validateName, trigger: 'blur' }
            ],
            date:[
              { required: true, message: '请选择日期', trigger: 'blur' }
            ]
          }
        },

        sensorList:[],
        sensorVibList:[],
      }
    },
    methods:{
      beforeDialogClose(done) {
        done();
      },
      handleCheckAll(val,data){
        this.$forceUpdate()
        data.isIndeterminate = false;
        console.log("点击",val,data)
        if (val){
          // this.form.model[data.id] = []
          // this.form.model.exportMap[data.id] = data.children.map(item => {return item.id})
          let tempList = Object.assign([], this.form.model.exportMap[data.id]);
          for (let item of data.children) {
            if (this.form.model.exportMap[data.id].indexOf(item.id) === -1) {
              tempList.push(item.id);
            }
          }
          this.form.model.exportMap[data.id] = tempList;
        } else {
          // this.form.model.exportMap[data.id] = [];
          let tempList = [];
          let idList = data.children.map(item => {return item.id});
          for (let item of this.form.model.exportMap[data.id]) {
            if (idList.indexOf(item) === -1) {
              tempList.push(item);
            }
          }
          this.form.model.exportMap[data.id] = tempList;
        }
      },
      handleVibCheckAll(val,data){
        this.$forceUpdate()
        data.isIndeterminate = false;
        console.log("点击",val,data)
        if (val){
          // this.form.model[data.id] = []
          // this.form.model.exportMap[data.id] = data.children.map(item => {return item.id})
          let tempList = Object.assign([], this.vibForm.model.exportMap[data.id]);
          for (let item of data.children) {
            if (this.vibForm.model.exportMap[data.id].indexOf(item.id) === -1) {
              tempList.push(item.id);
            }
          }
          this.vibForm.model.exportMap[data.id] = tempList;
        } else {
          // this.form.model.exportMap[data.id] = [];
          let tempList = [];
          let idList = data.children.map(item => {return item.id});
          for (let item of this.vibForm.model.exportMap[data.id]) {
            if (idList.indexOf(item) === -1) {
              tempList.push(item);
            }
          }
          this.vibForm.model.exportMap[data.id] = tempList;
        }
      },
      handleCheck(val,data){
        this.$forceUpdate()
        data.isIndeterminate = this.form.model.exportMap[data.id].length > 0 && this.form.model.exportMap[data.id].length < data.children.length;
        data.checkAll = !data.isIndeterminate
      },
      handleVibCheck(val,data){
        this.$forceUpdate()
        data.isIndeterminate = this.vibForm.model.exportMap[data.id].length > 0 && this.vibForm.model.exportMap[data.id].length < data.children.length;
        data.checkAll = !data.isIndeterminate
      },
      exportData(){
        this.$http.get('/sensor/export/list/point').then(res => {
          if (res.success){
            this.sensorList = res.data
            for(let item of this.sensorList){
              item.isIndeterminate = false
              item.checkAll = false
              this.form.model.exportMap[item.id] = []
            }
            this.dialogVisible = true
          }
        })

      },
      exportVibData(){
        this.$http.get('/sensor/export/list/vibPoint').then(res => {
          if (res.success){
            this.sensorVibList = res.data
            for(let item of this.sensorVibList){
              item.isIndeterminate = false
              item.checkAll = false
              this.vibForm.model.exportMap[item.id] = []
            }
            this.dialogVibVisible = true
          }
        })

      },
      exportFeatureData(){
        this.$http.get('/sensor/export/list/point').then(res => {
          if (res.success){
            this.sensorList = res.data
            for(let item of this.sensorList){
              item.isIndeterminate = false
              item.checkAll = false
              this.form.model.exportMap[item.id] = []
            }
            this.dialogFeatureVisible = true
          }
        })

      },
      resetFields(){
        this.dialogVisible = false;
      },
      resetVibFields(){
        this.dialogVibVisible = false;
      },
      resetFeatureFields() {
        this.dialogFeatureVisible = false;
      },
      submit(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.form.model.startDay = this.form.model.date[0]
            this.form.model.endDay = this.form.model.date[1]
            // delete this.form.model.date
            console.log(this.form.model);
            this.form.model.shorthand = localStorage.getItem('baseUrl')
            this.$http.post('/sensor/export/113/export', this.form.model).then(res => {
              if (res.success){
                this.$message.success("数据导出耗时较长，预计" + this.calculateTime() + "以上，届时请点击查询或刷新页面")
                this.pageGet(true)
                this.dialogVisible = false
              } else{
                this.$message.error(res.msg)
              }
            })
          }
        })
      },
      submitVib(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.vibForm.model.startDay = this.vibForm.model.date[0]
            this.vibForm.model.endDay = this.vibForm.model.date[1]
            // delete this.form.model.date
            console.log(this.form.model);
            this.$http.post('/sensor/export/exportVib', this.vibForm.model).then(res => {
              if (res.success){
                this.$message.success("数据正在导出，请稍后请点击查询或刷新页面")
                this.pageGet(true)
                this.dialogVibVisible = false
              } else{
                this.$message.error(res.msg)
              }
            })
          }
        })
      },
      submitFeature(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.form.model.startDay = this.form.model.date[0]
            this.form.model.endDay = this.form.model.date[1]
            // delete this.form.model.date
            console.log(this.form.model);
            this.form.model.shorthand = localStorage.getItem('baseUrl')
            this.$http.post('/sensor/export/113/exportFeature', this.form.model).then(res => {
              if (res.success){
                this.$message.success("数据正在导出，请稍后请点击查询或刷新页面")
                this.pageGet(true)
                this.dialogFeatureVisible = false
              } else{
                this.$message.error(res.msg)
              }
            })
          }
        })
      },
      // 计算导出时间
      calculateTime(){
        //相差天数
        let start = new Date(this.form.model.startDay);
        let end = new Date(this.form.model.endDay);
        let timeDiff = parseInt(end - start) / (24 * 3600 * 1000);
        let type = this.isSelected();
        // 导出一天数据的时间为45s（加上线程睡眠 3s），压缩时间约为 30s，因为计算的时间差少一天所以+1
        let time = (45 + 30) * (timeDiff + 1) * type;
        let timeOut;
        if (time >= 60 && time < 3600) {
          timeOut = (time/60).toFixed(2)  + "分钟";
        } else if (time >= 3600) {
          timeOut = (time/3600).toFixed(2) + "小时";
        } else {
          timeOut = time + "秒钟";
        }
        return timeOut;
      },
      // 是否有类型被选中
      isSelected(){
        let select = 0;
        for(let item of this.sensorList){
          if (this.form.model.exportMap[item.id].length !== 0) {
            select++;
          }
        }
        return select;
      },
      handleDownload(data) {
        window.location.href = data.path;
      },
      judgeVibTime(time) {
        // 一天
        let timeLimit = 1 * 3600 * 24 * 1000;
        let timeInterval = new Date(time[1]) - new Date(time[0])
        if (timeInterval > timeLimit) {
          console.log(timeInterval);
          console.log(timeLimit);
          this.$message.error("所选时间超过一天，请重新选择");
        }
      }
    }
  }
</script>

<style scoped>

</style>
